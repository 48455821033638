<template>
  <ul class="menu-nav">
    <!-- <template v-for="(item, i) in menu">
      <router-link :key="i" v-slot="{ href, navigate }" custom :to="item.href">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="{ 'menu-item-active': hasActiveChildren(item.parent) }"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> {{ item.label }} </span>
          </a>
        </li>
      </router-link>
    </template> -->
    <template v-for="(item, i) in menu">
      <router-link :key="i" v-slot="{ href, navigate }" custom :to="item.href">
        <li
          :key="i"
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="{ 'menu-item-active': hasActiveChildren(item.parent) }"
        >
          <a :href="href" class="menu-link menu-toggle" @click="navigate">
            <span class="menu-text">{{ item.label }}</span>
          </a>

          <div v-if="item.children" class="menu-submenu menu-submenu-fixed">
            <div class="menu-subnav megamenu" style="max-width: 1000px">
              <ul class="menu-content">
                <li v-for="(children, ic) in item.children" :key="ic" class="menu-item">
                  <h3 class="menu-heading menu-toggle">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">
                      {{ children.label }}
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg class="ml-2" :src="children.icon" />
                      </span>
                    </span>
                    <i class="menu-arrow"></i>
                  </h3>
                  <ul class="menu-inner">
                    <router-link
                      v-for="(children_children, icc) in children.children"
                      :key="icc"
                      v-slot="{ href, navigate }"
                      custom
                      :class="{ 'menu-item-active': subIsActive(children_children.href) }"
                      :to="children_children.href"
                    >
                      <li :key="icc" aria-haspopup="true" class="menu-item">
                        <a :href="href" class="menu-link" @click="navigate">
                          <span class="menu-text">{{ children_children.label }}</span>
                        </a>
                      </li>
                    </router-link>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </router-link>
    </template>
  </ul>
</template>

<script>
export default {
  name: 'KTMenu',
  data() {
    return {
      menu: [
        {
          label: 'Dashboard',
          href: '/dashboard',
          parent: '/dashboard'
        },
        {
          label: 'Perfil',
          href: '/profile/personal',
          parent: '/profile',
          children: [
            {
              label: 'Meu perfil',
              icon: '',
              children: [
                { label: 'Dados pessoais', href: '/profile/personal' },
                { label: 'Endereço', href: '/profile/address' },
                { label: 'Contato', href: '/profile/contact' },
                { label: 'Dados bancários', href: '/profile/bank' },
                { label: 'Segurança', href: '/profile/security' },
                { label: 'Validação de conta', href: '/profile/documents' }
              ]
            }
          ]
        },
        {
          label: 'Carteiras',
          href: '/financial/snail',
          parent: '/financial',
          children: [
            {
              label: 'SnailCoin',
              icon: '/media/svg/icons/Shopping/snail-money.svg',
              children: [
                { label: 'Extrato', href: '/financial/snail/extract' },
                { label: 'Transferência', href: '/financial/snail/transfer' },
                { label: 'Pagamentos', href: '/financial/snail/payment' }
              ]
            },
            {
              label: 'Real',
              icon: '/media/svg/icons/Shopping/Money.svg',
              children: [
                { label: 'Extrato', href: '/financial/real/extract', parent: '/financial/real' },
                { label: 'Transferência', href: '/financial/real/transfer', parent: '/financial/real' },
                { label: 'Depósitos', href: '/financial/real/deposit' },
                { label: 'Saques', href: '/financial/real/withdraw' },
                { label: 'Pagamentos', href: '/financial/real/payment' }
              ]
            }
          ]
        },
        { label: 'Emissão de SnailCoin', href: '/emission/proposals', parent: '/emission' },
        { label: 'Link de Pagamento', href: '/tools/payment_link', parent: '/tools/payment_link' }
      ]
    };
  }
};
</script>
